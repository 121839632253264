@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
/*navbar*/
.main-container .container-fluid{
  padding: 0;
  margin: 0;
  overflow: hidden;
  
}
.myrow{
  width: 96%;
  margin: auto;
}

.navlink-container{
display: flex;
justify-content: flex-end;
}
.nav-container{
  background: #000026;
  color: white;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.1 );
}
.navbar{
  width: 95%;
  margin-left: 10px;
  
}
/*Progress bar indicator*/
.progress-container {
  width: 100%;
  height: 8px; 
  background: #111152;
}

.progress-bar {
  height: 8px;
  background: #000026;
  width: 0%;
 transition: 0.5s linear;

}
/*logo-container*/
.logo{
  width: 100px;
  height: 100px;
  display: block;
  
}
.logo img{
  width: 100%;
  opacity: 1;
}
.nav-item {
  cursor: pointer;
  margin-left: 40px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.nav-link{
  color: white;
  padding: 0px;
  font-size: 18px;
}
.background{
  background: #000026;
  margin-top: 120px !important;
 }
 /*particle canvas css*/
.tsparticles-canvas-el{
  height: 82.5vh !important;
 }
.backgroundtext-container{
position: absolute;
width: 100%;
text-align: center;
top:10%;
}
.background-text{
font-family: 'Zilla Slab', serif;
color: white;
font-size:50px ;
word-wrap: break-word;
word-break: break-all;
font-weight: 700;
 }
 .background-button{
   outline: none !important;
   border: none;
   padding: 15px;
   background: transparent;
   box-shadow: 0 8px 32px 0 rgba(17, 20, 61, 0.37);
   backdrop-filter: blur( 0px );
   -webkit-backdrop-filter: blur( 0px );
   border-radius: 20px;
   border: 1px solid rgba( 255, 255, 255, 0.3 );
   color: white;
   text-transform: uppercase;
   font-family: 'Zilla Slab', serif;
 }
 .background-button a{
   text-decoration: none;
   color: inherit;
 }
 .background-button:hover{
  background: rgba(17, 20, 61, 1);
  outline: none;
 }
.about-container{
  background: #39b54a;
  text-align: center;
  height: 650px;
}
.about-heding{
  margin-top: 10px;
}
.about-heding p:nth-child(1){
  font-family: 'Poppins', sans-serif;
  color: black;
  font-weight: 1200;
  text-transform: capitalize ;
  text-align: center;
  font-size: 30px;

}
.about-heding p:nth-child(2){
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 1200;
  text-transform: capitalize ;
  text-align: center;
  font-size: 50px;
  margin-top: -20px;
}
.about-heding p:nth-child(4){
  font-family: 'Roboto', sans-serif;
  color: black;
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  width: 70%;
  margin: auto;
}
 .userprofile-heading{
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 50px;
}
.userprofile-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;

 }
 .userprofile-container>div{
   margin-top: 50px;
   margin-left: 50px;
 }
.user-profile{
  width: 25%;
  height: 400px;
  background: linear-gradient( #f0ecec, #fdfdfd);
  box-shadow:  2px 0px 5px #8c8a8a,
               1px 1px 0px #ffffff;
  position: relative;
}
  .user-profile img{
    width: 100%;
    height: 80%;
    opacity: 1;
    cursor: pointer;
  }
  /*Profile button on hover*/
  .userprofile-overlay{
    position: absolute;
    width: 0%;
    height: 80%;
    top:0;
    left: 0;
    background: rgba(34, 20, 20, 0.9);
    box-shadow: 0 8px 32px 0 rgba(24, 24, 22, 0.9);
    backdrop-filter: blur( 1px );
    -webkit-backdrop-filter: blur( 1px );
    color: white;
    transition: all 0.2s ease;
    opacity: 0;
}
.overlay-icon{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

}
.overlay-icon i{
  font-size: 30px;
  margin-left: 30px;
  cursor: pointer;
}
.overlay-icon i:hover{
  color: rgb(57, 52, 223);
}
.user-profile:hover .userprofile-overlay{
  opacity: 1;
  width: 100%;
  }
.userprofile-img-description{
text-align: center;
}
  .userprofile-img-description p:nth-child(1){
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .userprofile-img-description p:nth-child(2){
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: -15px;
  }

.developer{
  width: 100%;
  background: #111113;
  text-align: center;
  color: white;
  font-size: 10px;
}

.work-container{
  background: #030324;
  text-align: center;
  height: 600px;
  color: rgb(235, 230, 230);
}
.work-myrow{
  width: 70%;
  margin: auto;
  margin-top: 50px;
}
.work-heding p:nth-child(1){
margin-top: 0;
font-size: 40px;
}
.work-heding p:nth-child(2){
  margin-top: 100px;
  font-size: 20px;
  color: white;
  }

  .contact{
    margin-bottom: 50px;
  }

  .connect-heading{
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .contact-form input{
    margin:20px 0;
  }
  .contact-media-icon i
  {
    padding: 0px;
    margin:0 20px;
    font-size: 30px;
    cursor: pointer;
  }
  .contact-media-icon i:hover{
    color: rgb(57, 52, 223);
  }

  /*Media query*/
  @media screen and (max-width:1255px) {
  .user-profile{
    width: 25%;
    height: 320px;
  }
  .userprofile-img-description p:nth-child(1){
    font-size: 18px;
  }
  .userprofile-img-description p:nth-child(2){
    font-size: 14px;
  }
  }
  @media screen and (max-width:992px) {
    .navbar-nav .nav-item{
      padding: 10px;
    }
    .background-text{
      font-size:40px ;
       }
    .userprofile-container>div{
      margin-right: 40px;
      margin-left: 0;
    }
    .user-profile{
      width: 26%;
      height: 270px;
    }
    .userprofile-img-description p:nth-child(1){
      font-size: 14px;
    }
    .userprofile-img-description p:nth-child(2){
      font-size: 12px;
    }
    .work-heding p:nth-child(2){
      margin-top: 40px;
      font-size: 18px;
      color: white;
      }
  }

  @media screen and (max-width:767px) {
    .navbar-nav .nav-item{
      padding: 10px;
    }
    .background-text{
      font-size:30px ;
       }
    .about-container{
      background: #39b54a;
      text-align: center;
      height: 750px;
    }
    .about-heding p:nth-child(1){
      font-size: 30px;    
    }
    .about-heding p:nth-child(2){
           font-size: 40px;
     }
    .about-heding p:nth-child(4){
      font-size: 20px;
      width: 80%;
      margin: auto;
    }
    .userprofile-container>div{
      margin-right: 10px;
      margin-left: 40px;
    }
    .user-profile{
      width: 40%;
      height: 300px;
    }
    .userprofile-img-description p:nth-child(1){
      font-size: 14px;
    }
    .userprofile-img-description p:nth-child(2){
      font-size: 12px;
    }
    .work-heding p:nth-child(1){
      margin-top: 20px;
      font-size: 35px;
      color: white;
      }
    .work-heding p:nth-child(2){
      margin-top: 40px;
      font-size: 16px;
      color: white;
      }
  }

  @media screen and (max-width:560px) {
    .navbar-nav .nav-item{
      padding: 10px;
    }
    .background-text{
      margin-top: 50px;
      font-size:20px ;
       }
    .userprofile-container>div{
      margin-right: 10px;
      margin-left: 0px;
    }
    .user-profile{
      width: 60%;
      height: 350px;
    }
    .userprofile-img-description p:nth-child(1){
      font-size: 18px;
    }
    .userprofile-img-description p:nth-child(2){
      font-size: 14px;
    }
    .work-heding p:nth-child(1){
      margin-top: 0px;
      font-size: 30px;
      }
    .work-heding p:nth-child(2){
      margin-top: 20px;
      font-size: 16px;

      }
  }


  @media screen and (max-width:480px) {
    .navbar-nav .nav-item{
      padding: 10px;
    }
    .background-text{
      margin-top: 50px;
      font-size:20px ;
        }
    .userprofile-container>div{
      margin-right: 0px;
      margin-left: 0px;
    }
    .user-profile{
      width: 65%;
      height: 320px;
    }
    .userprofile-img-description p:nth-child(1){
      font-size: 17px;
    }
    .userprofile-img-description p:nth-child(2){
      font-size: 14px;
    }
    .work-container{
      height: 800px;

    }
    .work-heding p:nth-child(1){
      margin-top: 0px;
      font-size: 40px;
      }
    .work-heding p:nth-child(2){
      margin-top: 50px;
      font-size: 16px;

      }
  }

  @media screen and (max-width:383px) {
    .about-container{
      background: #39b54a;
      text-align: center;
      height: 750px;
    }
    .about-heding p:nth-child(1){
      font-size: 20px;    
    }
    .about-heding p:nth-child(2){
           font-size: 30px;
     }
    .about-heding p:nth-child(4){
      font-size: 18px;
      width: 80%;
      margin: auto;
    }
  }