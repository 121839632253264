*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
      padding: 0px;
      scroll-behavior: smooth;
}
body{
    background: #ffffff;
}
html{
    scroll-behavior: smooth;
}
